import { addOnDataType } from "@RHCommerceDev/component-product-addon/types";
import { AddOnDataType } from "@RHCommerceDev/component-product-addon-checkbox";

export const checkDisplayAddOns = (productAddonDisplayOptions: any) => {
  return productAddonDisplayOptions
    ? ["Checkbox", "Checkbox and Panel"].includes(
        productAddonDisplayOptions || ""
      )
    : true;
};

export const getAddOnByProductId = (addOns: any, productId: string) => {
  return addOns?.find((addOn: any) => addOn?.productId === productId);
};

export const checkOptionExistsInParentSelectedOptions = (
  parentChosenLineItemOptions,
  parentChosenLineItemOptionIds,
  options,
  option
) => {
  if (parentChosenLineItemOptions && parentChosenLineItemOptionIds?.length) {
    parentChosenLineItemOptionIds.forEach((id: string) => {
      if (id?.includes("_")) {
        const optionsList = id?.split("_");
        if (optionsList?.includes(option.id)) {
          option.status = "selected";
        }
      } else if (option.id === id) {
        option.status = "selected";
      } else if (options?.length === 1) {
        option.status = "selected";
      }
    });
  }

  return option;
};

export const getOtherAddOnByProductId = (addOns: any, productId: string) => {
  return addOns?.filter((addOn: any) => addOn?.productId !== productId);
};

export const createAddOnProductSet = ({
  existingAddOns,
  addOnProduct,
  mainItemOptionsChanged,
  fullSkuId,
  colorizedImgURL,
  spo,
  preBillMessage,
  pricing,
  qty,
  itemOptions,
  selectedOptions,
  maxQuantity
}): AddOnDataType[] => {
  const otherAddOns = getOtherAddOnByProductId(
    existingAddOns,
    addOnProduct?.productId || ""
  );

  const currentAddOn = getAddOnByProductId(
    existingAddOns,
    addOnProduct?.productId || ""
  );

  const commonData = {
    fullSkuId,
    colorizedImgURL,
    spo,
    preBillMessage,
    pricing,
    qty,
    itemOptions,
    selectedOptions,
    maxQuantity
  };
  if (currentAddOn) {
    return [
      ...otherAddOns,
      ...(addOnProduct?.productSelected || mainItemOptionsChanged
        ? [
            {
              ...currentAddOn,
              productSelected: addOnProduct?.productSelected,
              ...commonData
            }
          ]
        : [])
    ] as AddOnDataType[];
  }
  return [
    ...otherAddOns,
    {
      ...addOnProduct,
      ...commonData
    }
  ] as AddOnDataType[];
};

export const checkAddOnProductPreselected = (
  addOnProduct: AddOnDataType
): boolean => {
  return Boolean(
    addOnProduct?.productSelected &&
      addOnProduct?.selectedOptions?.length === addOnProduct?.optionsLength
  );
};

export const checkIsAddOnProductAndPreselected = (
  addOnProduct: AddOnDataType,
  item: AddOnDataType
): boolean => {
  return Boolean(
    item.productId === addOnProduct?.productId &&
      item.mainItemProductId === addOnProduct?.mainItemProductId &&
      checkAddOnProductPreselected(addOnProduct)
  );
};

export const checkAddOnProductDoesNotExistsInAddonItems = (
  prev: addOnDataType[],
  addOnProduct: AddOnDataType
): boolean => {
  return Boolean(
    !prev.some(
      item =>
        item.productId === addOnProduct?.productId &&
        item.mainItemProductId === addOnProduct?.mainItemProductId
    ) && checkAddOnProductPreselected(addOnProduct)
  );
};
