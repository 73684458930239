import { intersection } from "lodash";
import {
  checkIsMultiSkuProduct,
  setFeetAsDecimal,
  setFeetInchesAsDecimal
} from "./utils";
import { getSwatchOptionData } from "./swatchHelper";

export const getCustomProductOptions = ({ lineItem, customOptions }) => {
  const customProductOptions = {
    isCustom: true,
    customType:
      lineItem?.customProductOptions?.customProductInformation
        ?.customProductType
  };
  const { shape } =
    lineItem?.customProductOptions?.customProductInformation || {};

  if (shape === "rectangle") {
    return {
      ...customProductOptions,
      length: setFeetInchesAsDecimal(customOptions?.length),
      width: setFeetInchesAsDecimal(customOptions?.width)
    };
  } else if (shape === "round") {
    return {
      ...customProductOptions,
      diameter: setFeetInchesAsDecimal(customOptions?.diameter)
    };
  }
  return {};
};

export const handlePreSelectedOptions = (
  queryParamsVal: string,
  availableOptionsObj: ProductAvailableOptionSet,
  selectedOptions: string[],
  allSwatchGroups?: ProductSwatchGroup[],
  handleOnSwatchClick?: (swatchData: {
    swatchGroup: ProductSwatchGroup;
    selectedSwatch: ProductSwatch;
  }) => void
) => {
  let callQuery = false;
  if (availableOptionsObj) {
    let allOptions = availableOptionsObj?.options?.map(option => option?.id);
    const similarKeys = intersection(allOptions, selectedOptions);
    if (similarKeys?.length === 0) {
      const selectedOption = availableOptionsObj?.options?.find(
        option => option?.value?.toLowerCase() === queryParamsVal?.toLowerCase()
      );

      if (selectedOption) {
        selectedOptions = [...selectedOptions, selectedOption.id];
        callQuery = true;
        const swatchGroup =
          allSwatchGroups?.find(
            it => it.groupMaterial === selectedOption?.type
          ) || null;
        const selectedSwatch =
          swatchGroup?.stockedSwatches?.find(
            it => it.options?.[0]?.id === selectedOption?.id
          ) || null;
        if (swatchGroup && selectedSwatch && handleOnSwatchClick) {
          const swatchData = {
            swatchGroup: swatchGroup,
            selectedSwatch: selectedSwatch
          };
          handleOnSwatchClick(swatchData);
        }
      }
    }
  }

  return { selectedOptions, callQuery };
};

export const getOptionSetFromAvailableOptions = (availableOptions, key) => {
  return availableOptions.find(
    optionSet => optionSet.type.toLowerCase() === key.toLowerCase()
  );
};

export const getCustomProductRugTrimById = (
  customProductOptions: CustomProductOption,
  optionId: string
) => {
  return (
    customProductOptions?.customProductInformation?.rugTrims?.find(
      ({ id }) => id === optionId
    )?.code ?? ""
  );
};

export const checkIsAllCustomOptionsSelected = ({
  shape,
  rugTrims,
  rugTrim,
  totalWidth,
  totalLength,
  feet
}: {
  shape: string;
  rugTrims: Maybe<CustomOption[]>;
  rugTrim: string;
  totalLength?: number;
  totalWidth?: number;
  feet?: number | string;
}): boolean => {
  if (shape === "rectangle") {
    if (rugTrims && rugTrim && totalWidth && totalLength) {
      return true;
    }
    return Boolean(!rugTrims && totalWidth && totalLength);
  }
  if (shape === "round") {
    if (feet) {
      if (rugTrims && rugTrim) {
        return true;
      }
      return Boolean(!rugTrims);
    }
    return false;
  }
  return false;
};

export const initializeOptionTypeMapper = (
  availableOptions: ProductAvailableOptionSet[]
) => {
  const optionTypeMapper = {};
  availableOptions?.forEach(optionSet => {
    optionTypeMapper[optionSet?.options[0]?.type] = "";
  });
  return optionTypeMapper;
};

export const getSelectedOptionsFromAvailableOptions = (
  availableOptions: ProductAvailableOptionSet[]
) => {
  let selectedOptions = {};
  availableOptions?.forEach(optionSet => {
    optionSet?.options?.forEach(option => {
      if (option?.status === "selected") {
        selectedOptions[option?.type] = option?.id;
      }
    });
  });

  return selectedOptions;
};

export const getSelectedOptions = (
  response: any,
  swatches: any,
  lineItem: any,
  getDiffOptions: boolean = false,
  forcedOption: { [key: string]: string } = {},
  isMultiSku: boolean = false
) => {
  let lineItemPreselected: { [key: string]: any } = {};

  const availableOptionIds = new Set<string>();
  lineItem?.availableOptions?.forEach(optionSet => {
    optionSet?.options?.forEach(option => {
      // if (option?.status !== "unavailable") {
      availableOptionIds.add(option?.id);
      // }
    });
  });

  if (response?.availableOptions) {
    response?.availableOptions?.map((option: ProductAvailableOptionSet) => {
      const options = option?.options?.filter(o => o?.status !== "unavailable");
      // if (options?.length === 1) {
      //   const childOption = options?.[0];
      //   lineItemPreselected[childOption?.type] = childOption?.id;
      // }
      options?.map((childOption: ProductAvailableOption) => {
        if (childOption?.status === "selected") {
          lineItemPreselected[childOption?.type] = childOption?.id;
        }
      });
    });
  }

  const swatchOptionsData = getSwatchOptionData(swatches);

  const combinedOptions = {
    ...lineItemPreselected
  };

  Object.entries(swatchOptionsData ?? {}).forEach(([key, value]: any) => {
    if (availableOptionIds.has(value)) {
      combinedOptions[key] = value;
    }
  });

  let selectedOptions: string[] = Object?.values({
    ...combinedOptions,
    ...forcedOption
  });

  const lineItemPreselectedOptions = Object.values(lineItemPreselected || {});
  const swatchOptions = Object.values(getSwatchOptionData(swatches) || {});

  if (lineItemPreselectedOptions.length === [...availableOptionIds]?.length) {
    const lineItemPreselectedValue = lineItemPreselectedOptions[0];
    const isNotInSwatches = !swatchOptions.includes(lineItemPreselectedValue);

    if (isNotInSwatches) {
      selectedOptions = Object?.values({
        ...getSwatchOptionData(swatches),
        ...lineItemPreselected,
        ...forcedOption
      });
    }
  }

  const filteredOptions: string[] = selectedOptions?.filter(selectedOption => {
    const isOptionAvailable = availableOptionIds.has(selectedOption);
    return isOptionAvailable;
  });

  if (getDiffOptions) {
    return selectedOptions?.filter(x => !filteredOptions?.includes(x));
  }
  if (isMultiSku) {
    return selectedOptions;
  }

  return filteredOptions;
};

export const getColorPreselectedAndSelectedOptions = ({
  queryParams,
  response,
  selectedSwatches,
  lineItem,
  allSwatchGroups,
  handleOnSwatchClick
}): { isColorPreSelected: boolean; selectedOptions: string[] } => {
  let isColorPreSelected = false;

  let selectedOptions = getSelectedOptions(
    response,
    selectedSwatches,
    lineItem
  );

  Object.keys(queryParams || {}).forEach(key => {
    const matchingOptionSet = getOptionSetFromAvailableOptions(
      response?.availableOptions,
      key
    );
    if (matchingOptionSet) {
      const { selectedOptions: preSelectedOptions, callQuery } =
        handlePreSelectedOptions(
          queryParams?.[key],
          matchingOptionSet,
          selectedOptions,
          allSwatchGroups,
          handleOnSwatchClick
        );
      selectedOptions = preSelectedOptions;
      isColorPreSelected = callQuery;
    }
  });

  return {
    isColorPreSelected,
    selectedOptions
  };
};

export const checkIsProductFullyConfigured = (
  availableOptions,
  selectedOptions
) => {
  return availableOptions?.length === selectedOptions?.length;
};

export const checkAllOptionSelectedOrIsColorPreSelected = (
  selectedOptions,
  availableOptions,
  isColorPreSelected
) => {
  return (
    checkIsProductFullyConfigured(availableOptions, selectedOptions) ||
    !!isColorPreSelected
  );
};

export const checkIsMultiSkuProductFullyConfigured = (
  response: any,
  selectedOptions: string[],
  isMetaProduct: boolean
) => {
  const isProductFullyConfigured = checkIsProductFullyConfigured(
    response?.availableOptions,
    selectedOptions
  );

  const isMultiSkuProduct = checkIsMultiSkuProduct(
    response?.sku?.fulfillmentEta?.fullSkuId,
    isMetaProduct
  );

  return isProductFullyConfigured && isMultiSkuProduct;
};

export const createRoundCustomProductConfig = (
  circleOptions: { feet: string | number; inches: string | number },
  isGiftRegistryDialog: boolean,
  customProductOptions: CustomProductOption,
  rugTrim: string
) => {
  const { feet, inches } = circleOptions;
  const parsedFeet = Number(feet);
  const parsedInches = Number(inches);
  return isGiftRegistryDialog
    ? {
        diameter: setFeetInchesAsDecimal({
          feet: parsedFeet,
          inches: parsedInches
        }),
        width: 0,
        length: 0,
        trim: getCustomProductRugTrimById(customProductOptions || {}, rugTrim),
        type: "rug",
        fulfillmentCode:
          customProductOptions?.customProductInformation?.cwCustomProductCode ??
          ""
      }
    : {
        customProductDiameter: String(
          setFeetAsDecimal({ feet: parsedFeet, inches: parsedInches })
        ),
        customProductTrim: rugTrim,
        customProductType: "rug",
        rug: true
      };
};

export const createRectangleCustomProductConfig = (
  rectangleOptions: any,
  isGiftRegistryDialog: boolean,
  rugTrim,
  customProductOptions: CustomProductOption,
  skuPriceInfo: any
) => {
  const { width, length } = rectangleOptions;
  const parsedWidth = {
    feet: Number(width?.feet),
    inches: Number(width?.inches)
  };
  const parsedLength = {
    feet: Number(length?.feet),
    inches: Number(length?.inches)
  };
  const totalWidth = isGiftRegistryDialog
    ? setFeetInchesAsDecimal(parsedWidth)
    : setFeetAsDecimal(parsedWidth);
  const totalLength = isGiftRegistryDialog
    ? setFeetInchesAsDecimal(parsedLength)
    : setFeetAsDecimal(parsedLength);

  //ignore fulfillmentCode for custom rugs SR-72
  const fulfillmentCode = skuPriceInfo?.sendCwCustomProductCode
    ? customProductOptions?.customProductInformation?.cwCustomProductCode ?? ""
    : undefined;

  return isGiftRegistryDialog
    ? {
        length: totalLength,
        width: totalWidth,
        trim:
          customProductOptions?.customProductInformation?.rugTrims?.find(
            ({ id }) => id === rugTrim
          )?.code ?? "",
        type: "rug",
        fulfillmentCode: fulfillmentCode
      }
    : {
        customProductLength: String(totalLength),
        customProductWidth: String(totalWidth),
        customProductTrim: rugTrim,
        customProductType: "rug",
        rug: true
      };
};
