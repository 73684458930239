import React from "react";

interface InputObject {
  startAdornment?: React.ReactNode;
  value?: string | number;
  defaultValue?: string | number;
}

export function hasValue(value: any): boolean {
  return value != null && !(Array.isArray(value) && value.length === 0);
}

export function isFilled(obj: InputObject | null | undefined): boolean {
  var SSR =
    arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

  return (
    obj &&
    ((hasValue(obj.value) && obj.value !== "") ||
      (SSR && hasValue(obj.defaultValue) && obj.defaultValue !== ""))
  );
}

export function isAdornedStart(obj: InputObject | null | undefined): boolean {
  return !!obj?.startAdornment;
}
