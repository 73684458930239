import { find, flatMap } from "lodash";
import { isFinishOrColorOption } from "./utils";

export const getSwatchGroupByType = (
  swatchGroups: ProductSwatchGroup[],
  type: string
) => {
  return (
    swatchGroups?.find(swatchGroup => swatchGroup?.groupMaterial === type) ||
    null
  );
};

export const getSwatchOptionData = (selectedSwatches: { [key: string]: any }) =>
  selectedSwatches &&
  Object?.values(selectedSwatches)?.reduce((prev, next) => {
    let obj: any = { ...prev };
    next?.options?.map((opt: any) => {
      if (next?.groupMaterial) {
        obj[next?.groupMaterial] = opt?.id;
      }
    });
    next?.options?.map((opt: any) => {
      if (opt?.optionType) {
        obj[opt?.optionType] = opt?.id;
      }
    });
    return obj;
  }, {});

export const populateSwatchesToOptions = (
  selectedSwatches?: ProductSwatch[],
  availableOptions?: ProductAvailableOptionSet[]
): string[] => {
  return (
    selectedSwatches?.reduce(
      (acc, swatch) =>
        swatch.options.reduce((options, option) => {
          const selectedOption = availableOptions
            ?.find(
              availableOption => availableOption.type === option.optionType
            )
            ?.options?.find(opt => opt.id === option.id);
          return [...options, ...(selectedOption ? [selectedOption.id] : [])];
        }, acc),
      [] as string[]
    ) ?? []
  );
};

export const getSwatchById = (swatches: ProductSwatch[], optionId: string) => {
  return swatches?.find(swatch => swatch.options?.[0]?.id === optionId) || null;
};

export const getSwatchDataFromSelectedOptions = ({
  swatchGroup,
  selectedSwatch,
  selectedOption,
  allSwatchGroups,
  selectedOptions
}) => {
  if (!(swatchGroup && selectedSwatch)) {
    return;
  }

  if (isFinishOrColorOption(swatchGroup?.groupMaterial || "")) {
    return {
      swatchGroup: swatchGroup,
      selectedSwatch: selectedSwatch
    };
  } else if (selectedOption?.type === "Color") {
    let allSwatches: ProductSwatch[] = [];
    allSwatchGroups?.forEach((obj: ProductSwatchGroup) => {
      const stockedSwatches = obj?.stockedSwatches || [];
      const customSwatches = obj?.customSwatches || [];
      allSwatches = allSwatches.concat(stockedSwatches, customSwatches);
    });
    const swatchGroup = find(allSwatchGroups, parent =>
      flatMap(
        [...(parent.stockedSwatches || []), ...(parent.customSwatches || [])],
        "options"
      ).some(option => option.id === selectedOption?.id)
    );
    const selectedSwatch =
      allSwatches?.find((it: any) => {
        const optionObj = it?.options?.find(
          opItem => opItem?.optionType === "Color"
        );
        return optionObj?.id === selectedOption?.id;
      }) || null;

    return {
      swatchGroup: swatchGroup,
      selectedSwatch: {
        ...selectedSwatch,
        options: selectedOptions.find(it => it.type === "Fabric")
          ? selectedSwatch.options
          : selectedSwatch.options.filter(
              option => option.optionType === "Color"
            )
      }
    };
  }
};
