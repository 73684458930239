import React, { forwardRef, ForwardRefRenderFunction } from "react";
import clsx from "clsx";
import { useFormControl } from "@material-ui/core";
import formControlState from "@material-ui/core/FormControl/formControlState";
import { twMerge } from "tailwind-merge";
import { ClassNameMap } from "../types/StandardProps";
import overrideStyles, { addDebugInfo } from "../utils/overrideStyles";
import { FormLabelClassKey, FormLabelProps } from "./types";

/*
 ! Refer to the README.md in the TailwindTextField component for guidelines on styling, adding styles, and debugging.
  ! Avoid using "!important" in className props, passed classes, or componentStyles within the component.

  To override `FormLabel` componentStyles with Tailwind CSS, use the classes prop and specify the keys for componentStyles you want to override 

  Ex: To override componentStyles corresponding to `root` and `focused` key, we can do as follow 

  <FormLabel
  classes={{
  root: "....",
  focused: "...."
  }}>
  Email
  </FormLabel>

*/

const componentStyles: ClassNameMap<FormLabelClassKey> = {
  /* Styles applied to the root element. */
  root: "text-[rgba(0,0,0,0.54)] p-0 text-[1rem] font-primary-rhsans font-[400] leading-[1] tracking-[0.00938em] cursor-pointer",

  /* Styles applied to the root element if the color is secondary and `focused={true}` . */
  "colorSecondary:focused": "text-[rgba(245,0,87,1)]",

  /* Styles applied to the root element if `focused={true}`. */
  focused: "text-[rgba(63,81,181,1)]",

  /* Styles applied to the root element if `required={true}`. */
  required: "",

  /* Styles applied to the root element if `error={true}`. */
  error: "text-[rgba(244,67,54,1)]",

  /* Styles applied to the root element if `disabled={true}`. */
  disabled: "text-[rgba(0,0,0,0.38)]",

  /* Styles applied to the asterisk element. */
  asterisk: ""
};

const FormLabelRenderFunction: ForwardRefRenderFunction<
  HTMLLabelElement,
  FormLabelProps
> = (props, ref) => {
  const {
    children,
    classes,
    className: classNameProp,
    color,
    component: Component = "label",
    disabled,
    error,
    focused,
    required,
    ...other
  } = props;

  /* overrideStyles iterates through each key in componentStyles and overrides its styles with the corresponding styles from classes for matching keys. */
  const overriddenStyles: ClassNameMap<FormLabelClassKey> = overrideStyles(
    "FormLabel",
    componentStyles,
    classes
  );

  const muiFormControl = useFormControl();
  const fcs = formControlState({
    props,
    muiFormControl,
    states: ["color", "required", "focused", "disabled", "error", "filled"]
  });

  /*
  ! ⚠️DO NOT change the order of styles inside the `className` prop passed to the `Component` in the return statement, as styles defined later will override the common attributes of the earlier ones. Changing the order may result in unexpected behavior.
 */
  return (
    <Component
      className={twMerge(
        clsx(
          overriddenStyles.root,
          addDebugInfo("FormLabel", "className", classNameProp ?? ""),
          { [overriddenStyles.focused]: fcs.focused },
          {
            [overriddenStyles["colorSecondary:focused"]]:
              fcs.focused && fcs.color === "secondary"
          },
          { [overriddenStyles.required]: fcs.required },
          { [overriddenStyles.error]: fcs.error },
          { [overriddenStyles.disabled]: fcs.disabled }
        )
      )}
      ref={ref}
      {...other}
    >
      {children}
      {fcs.required && (
        <span aria-hidden={true} className={overriddenStyles.asterisk}>
          {"\u2009"}*
        </span>
      )}
    </Component>
  );
};

export const FormLabel: any = forwardRef(FormLabelRenderFunction);
