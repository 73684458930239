import { processEnvServer } from "@RHCommerceDev/hooks/useSsrHooks";
import { currencySymbolToCurrencyCodeMapper } from "@RHCommerceDev/resources/countries-config.json";
import analyticsLoader from "analytics/loader";
import yn from "yn";
import { useEnv } from "@RHCommerceDev/hooks/useEnv";

export const useRelatedProductCardAnalytics = () => {
  const env = useEnv();

  const getRootHtmlElement = (): HTMLElement => {
    return document.querySelector("#spa-root > *")!;
  };

  const triggerAnalyticsEvent = (data?: any) => {
    if (!processEnvServer) {
      const itemList = [
        {
          displayName: data?.displayName,
          productId: data?.id,
          quantity: null,
          sku: null,
          price: data?.priceRangeDisplay,
          currencyCode:
            currencySymbolToCurrencyCodeMapper[
              data?.priceRangeDisplay?.currencySymbol ?? "$"
            ]
        }
      ];

      analyticsLoader(a =>
        a.emitAnalyticsEvent(
          getRootHtmlElement(),
          a.EVENTS.SELECT_ITEM.INT_TYPE,
          {
            itemList,
            item_list_name: true,
            id: data?.id,
            displayName: data?.displayName
          }
        )
      );
    }
  };

  const triggerAddToWishlistAnalyticsEvent = (
    data: any,
    skuDetails: any,
    quantity: number,
    pli: any
  ) => {
    if (!yn(env.FEATURE_ANALYTICS_FORM_DATALAYER)) {
      return;
    }

    analyticsLoader(a => {
      a?.emitAnalyticsEvent(
        getRootHtmlElement(),
        a?.EVENTS?.GA4_WISHLIST?.INT_TYPE,
        {
          item: {
            id: data?.id,
            name: data?.displayName,
            category: data?.category ?? "",
            sku: skuDetails?.fullSkuId ?? "",
            quantity,
            pricing: pli?.sku?.info?.skuPriceInfo?.listPrice || 0,
            currencyCode:
              currencySymbolToCurrencyCodeMapper[
                data?.priceRangeDisplay?.currencySymbol ?? "$"
              ]
          },
          item_list_name: "pdp",
          value: (pli?.sku?.info?.skuPriceInfo?.listPrice || 0) * quantity
        }
      );
    });
  };

  const triggerAddToCartAnalyticsEvent = (
    data: any,
    cartDetails: any,
    quantity: number,
    skuDetails: any,
    pli: any,
    urlParams: any
  ) => {
    analyticsLoader(a =>
      a?.emitAnalyticsEvent(
        getRootHtmlElement(),
        a?.EVENTS?.GA4_ADD_TO_CART?.INT_TYPE,
        {
          cartdetails: {
            cartId: cartDetails?.id,
            cartCreationDate: cartDetails?.createdAt,
            cartUpdationDate: cartDetails?.lastModifiedAt,
            currency: cartDetails?.cartPrice?.currencyCode
          },
          item: {
            name: data?.displayName,
            id: data?.id,
            quantity: quantity,
            sku: skuDetails?.fullSkuId ?? "",
            pricing: (pli?.sku?.info?.skuPriceInfo?.listPrice || 0) * quantity,
            currencyCode: cartDetails?.cartPrice?.currencyCode,
            color: pli?.availableOptions
              ?.find(opt => opt?.type?.includes("Color"))
              ?.options?.find(opt => opt?.status === "selected")?.value,
            atc_method: urlParams?.prodId === data?.id ? "featured" : "related"
          },
          store_number: "Website",
          item_list_name: "RelatedProductCard"
        }
      )
    );
  };

  return {
    triggerAnalyticsEvent,
    triggerAddToWishlistAnalyticsEvent,
    triggerAddToCartAnalyticsEvent
  };
};
