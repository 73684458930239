import { ClassNameMap } from "../types/StandardProps";
import { twMerge } from "tailwind-merge";

export default function overrideStyles<ClassKey extends string>(
  componentName: string,
  styles: ClassNameMap<ClassKey>,
  classes: Record<string, string> | undefined
): ClassNameMap<ClassKey> {
  let overriddenStyles = {} as ClassNameMap<ClassKey>;

  Object.keys(styles).forEach(key => {
    overriddenStyles[key] = addDebugInfo(
      componentName,
      key,
      twMerge(concatUtil(styles, classes, key))
    );
  });

  return overriddenStyles;
}

export function concatUtil<ClassKey extends string>(
  styles: ClassNameMap<ClassKey>,
  classes: Record<string, string> | undefined,
  key: string
): string {
  let result = "";

  if (styles?.[key]) {
    result = styles[key];
  }

  if (classes?.[key]) {
    result += ` ${classes[key]}`;
  }

  return result;
}

export function addDebugInfo(
  componentName: string,
  key: string,
  styles: string
): string {
  return ` ${componentName}$${key} = { ${styles} }`;
}
