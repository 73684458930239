import { SaleContextFilter } from "@RHCommerceDev/graphql-client/queries/app";
import { RefetchOptionsType } from "../types";

export const createInitialRefetchOptions = (
  data: any,
  isMainProduct: boolean,
  _fullSkuId: string,
  postalCode: string,
  saleContextFilter?: SaleContextFilter
): RefetchOptionsType => {
  return {
    productId: data?.id,
    fullSkuId: _fullSkuId || undefined,
    monogrammable: data?.personalizeInfo?.monogrammable,
    postalCode: postalCode,
    selectedOptionIds: [],
    qty: 1,
    filter: isMainProduct || data?.onSale ? saleContextFilter : null,
    shouldFetchSku: false
  };
};
