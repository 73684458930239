export interface Cancelable {
  clear(): void;
}

export default function debounce<T extends (...args: any[]) => any>(
  func: T,
  wait: number = 166
): T & Cancelable {
  let timeout: ReturnType<typeof setTimeout>;

  const debounced = function (this: any, ...args: Parameters<T>) {
    const later = () => {
      func.apply(this, args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };

  debounced.clear = function () {
    clearTimeout(timeout);
  };

  return debounced as T & Cancelable;
}
