import React, { forwardRef, ForwardRefRenderFunction } from "react";
import clsx from "clsx";
import { useFormControl } from "@material-ui/core";
import formControlState from "@material-ui/core/FormControl/formControlState";
import { twMerge } from "tailwind-merge";
import { FormHelperTextClassKey, FormHelperTextProps } from "./types";
import { ClassNameMap } from "../types/StandardProps";
import overrideStyles, { addDebugInfo } from "../utils/overrideStyles";

/*
 ! Refer to the README.md in the TailwindTextField component for guidelines on styling, adding styles, and debugging.
  ! Avoid using "!important" in className props, passed classes, or componentStyles within the component.

  To override `FormHelperText` componentStyles with Tailwind CSS, use the classes prop and specify the keys for componentStyles you want to override 

  Ex: To override componentStyles corresponding to `root` and `maringDense` key, we can do as follow 

  <FormHelperText
  classes={{
  root: "....",
  marginDense: "...."
  }}>
  Please fill the form 
  </FormHelperText>

*/

const componentStyles: ClassNameMap<FormHelperTextClassKey> = {
  /* Base styles applied to root component*/
  root: "text-[rgba(0,0,0,0.54)] m-0 text-[1rem] mt-[3px] text-left font-primary-rhsans font-[400] leading-[1.66] tracking-[0.03333em]",

  /* Styles applied to the root component if `error={true}`. */
  error: "text-[rgba(202,102,103,1)]",

  /* Styles applied to the root component if `disabled={true}`. */
  disabled: "text-[rgba(0,0,0,0.4)]",

  /* Styles applied to the root component if `margin="dense"`. */
  marginDense: "mt-[4px]",

  /* Styles applied to the root component if the component is focused. */
  focused: "",

  /* Styles applied to root component if variant is filled or outlined */
  contained: "ml-[14px] mr-[14px]",

  /* Styles applied to the root component element if `required={true}`. */
  required: ""
};

const FormHelperTextRenderFunction: ForwardRefRenderFunction<
  HTMLParagraphElement,
  FormHelperTextProps
> = (props, ref) => {
  const {
    children,
    classes,
    className: classNameProp,
    component: Component = "p",
    disabled,
    error,
    focused,
    margin,
    required,
    variant,
    ...other
  } = props;

  /* overrideStyles iterates through each key in componentStyles and overrides its styles with the corresponding styles from classes for matching keys. */
  const overriddenStyles: ClassNameMap<FormHelperTextClassKey> = overrideStyles(
    "FormHelperText",
    componentStyles,
    classes
  );

  const muiFormControl = useFormControl();

  const fcs = formControlState({
    props,
    muiFormControl,
    states: [
      "variant",
      "margin",
      "disabled",
      "error",
      "filled",
      "focused",
      "required"
    ]
  });

  /*
  ! ⚠️DO NOT change the order of styles inside the `className` prop passed to the `Component` in the return statement, as styles defined later will override the common attributes of the earlier ones. Changing the order may result in unexpected behavior.
 */

  return (
    <Component
      ref={ref}
      className={twMerge(
        clsx(
          overriddenStyles.root,
          {
            [overriddenStyles.contained]:
              fcs.variant === "filled" || fcs.variant === "outlined"
          },
          addDebugInfo("FormHelperText", "className", classNameProp ?? ""),
          {
            [overriddenStyles.marginDense]: fcs.margin === "dense"
          },
          { [overriddenStyles.required]: fcs.required },
          { [overriddenStyles.focused]: fcs.focused },
          { [overriddenStyles.error]: fcs.error },
          { [overriddenStyles.disabled]: fcs.disabled }
        )
      )}
      {...other}
    >
      {children === " " ? (
        <span
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: "&#8203;" }}
        />
      ) : (
        children
      )}
    </Component>
  );
};

export const FormHelperText = forwardRef(FormHelperTextRenderFunction);
