export const setFeetInchesAsDecimal = ({ feet = 0, inches = 0 }) => {
  return Number(feet) * 12 + Number(inches);
};

export const setFeetAsDecimal = ({ feet = 0, inches = 0 }) => {
  return Number(feet) + Number(inches) / 12;
};

export const isFinishOrColorOption = optionType => {
  return ["Finish", "Color"].includes(optionType);
};

export const getFinishAndColorOption = (options: ProductAvailableOption[]) => {
  return options.find((option: ProductAvailableOption) =>
    isFinishOrColorOption(option?.type)
  );
};

export const checkIsMultiSkuProduct = (
  fullSkuId: string = "",
  isMetaProduct: boolean = false
) => {
  return ["m", "M"]?.includes(fullSkuId?.[0]) && isMetaProduct;
};

export const getProductPageUrlPathName = (isUrlChange: boolean = false) => {
  return isUrlChange ? "pdp" : "product.jsp";
};

export const getFullSkuIdFromRelatedProductCardData = ({
  data,
  fullSkuId,
  params,
  pathname
}) => {
  const preconfiguredSku = data?.preconfiguredSku || "";

  if (data?.productLineItem?.availableOptions?.length) {
    return data?.id === params?.productId || pathname?.includes(data?.id)
      ? fullSkuId || preconfiguredSku
      : "";
  }
  return data?.productLineItem?.sku?.fulfillmentEta?.fullSkuId || "";
};
