import React from "react";

// Utility function to set the ref
function setRef<T>(
  ref:
    | React.MutableRefObject<T>
    | ((instance: T | null) => void)
    | null
    | undefined,
  value: T
): void {
  if (typeof ref === "function") {
    ref(value);
  } else if (ref) {
    ref.current = value;
  }
}

// Custom hook to fork refs
function useForkRef<T>(refA?: React.Ref<T>, refB?: React.Ref<T>): React.Ref<T> {
  return React.useMemo(() => {
    if (refA == null && refB == null) {
      return null;
    }

    return refValue => {
      setRef(refA, refValue);
      setRef(refB, refValue);
    };
  }, [refA, refB]);
}

export default useForkRef;
